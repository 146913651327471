<template>
	<div class="footer-main" v-if="showFooter">
		<!--<div class="footer-top" v-if="$route.name === 'subscribe'">
			<div class="wrapper">
				<div class="footer_text-2">
					Нажимая кнопку «Получить доступ», вы подтверждаете заказ услуги «Доступ к «Игровому порталу Tele2» на условиях
					<router-link to="/info">пользовательского соглашения</router-link>. Провайдер ООО «ФМК». Стоимость: 9 руб./сут. (с НДС). Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого счета Абонента.
				</div>
			</div>
		</div>-->
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">12+ © Tele2 Россия, {{ year() }}</div>
					<div class="footer_text-3">Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie в соответствии с <a href="https://tele2.ru/api/media/content?contentId=m3100004">Политикой Компаний Группы Tele2</a> в области использования файлов cookie</div>
					<router-link to="/info" target="_blank" class="footer_link">Условия сервиса</router-link>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		},
        computed: {
            showFooter() {
                return this.$route.name !== 'play';
            }
        },
	}
</script>

import Vue from 'vue';
import App from './App.vue'
import axios from "axios";
import router from "./router";

require('./settings/settings');

// Production Tip
Vue.config.productionTip = false;
Vue.config.local = location.hostname === 'localhost';

// Plugins initial
window.axios = Vue.prototype.axios = axios;

// Events
window.events = new Vue();

// auth
window.auth = {
	msisdn: null,
	authGot: false
};

new Vue({
	render: h => h(App),
	router
}).$mount('#app')
